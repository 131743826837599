



















import inputMixin from 'library/mixins/input-mixin'
export default

	mixins: [ inputMixin ]

	props:
		name: String
		label: String
		value: Boolean
		required: Boolean

		# Loading state is used in flyout menu
		loading: Boolean

