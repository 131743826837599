



















import inputMixin from 'library/mixins/input-mixin'
export default

	mixins: [ inputMixin ]

	props:

		half:
			type: Boolean
			default: false

		noBorder:
			type: Boolean
			default: false

		name:
			type: String
			default: ''

		readonly:
			type: Boolean
			default: false

		placeholder:
			type: String
			default: null

	computed:

		classes: -> [
			'half' if @half
			'no-border' if @noBorder
		]

		computedPlaceholder: ->
			if @placeholder then @placeholder else "mm-dd-yyyy#{if @required then ' *' else ''}"
		computedLabel: -> "#{@label}#{if @required then ' *' else ''}"


