import { render, staticRenderFns } from "./newsletter.vue?vue&type=template&id=59b32afc&scoped=true&lang=pug&"
import script from "./newsletter.vue?vue&type=script&lang=coffee&"
export * from "./newsletter.vue?vue&type=script&lang=coffee&"
import style0 from "./newsletter.vue?vue&type=style&index=0&id=59b32afc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59b32afc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Wysiwyg: require('/opt/build/repo/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default,FormDatefield: require('/opt/build/repo/library/components/globals/form/datefield.vue').default,FormTextfield: require('/opt/build/repo/library/components/globals/form/textfield.vue').default,FormCheckbox: require('/opt/build/repo/library/components/globals/form/checkbox.vue').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default,ResponsiveCraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/responsive-craft-visual.vue').default})
