























































export default

	props: block: Object

	data: ->
		form:
			date: ""
			email: ""
			terms: false
		error: false
		errors: []
		success: false
		loading: false
		isMobile: null

	computed:

		sizes: -> "(min-width: 768px) 800px, (max-width: 767px) 65vw"

		classes: -> [if !@hasDate and @isMobile then 'empty']

		hasDate: -> !!@form.date

		# Simplify Newsletter Fields
		newsletter: -> @$store.state.globals.newsletterSignup

		image: -> @newsletter.image

		jotFormID: -> @newsletter.jotFormID

		datePrompt: -> @newsletter.datePrompt

		emailPrompt: -> @newsletter.emailPrompt

		termsCopy: -> @newsletter.termsCopy

		successMessage: -> @newsletter.successMessage

		tealiumEndpointBase: -> "https://collect.tealiumiq.com/event?"
		tealiumEndpoint: -> @tealiumEndpointBase + @tealiumParams

		# Post Data for JotForm
		jotFormEndpoint: -> "https://submit.jotform.com/submit/#{@jotFormID}"

		dateArray: -> @form?.date?.split('-')

		year: -> @dateArray?[0] || ""
		month: -> @dateArray?[1] || ""
		day: -> @dateArray?[2] || ""

		countryCode: -> @$storefront.country?.toLowerCase()
		languageCode: -> @$storefront.language?.toLowerCase()

		params: ->
			_params = new URLSearchParams()
			_params.append 'formID', @jotFormID
			_params.append 'q8_date[month]', @month
			_params.append 'q8_date[day]', @day
			_params.append 'q8_date[year]', @year
			_params.append 'q5_email]', @form.email
			_params.append 'q6_typeA[]]', @form.terms
			return _params


	 	# Tealium Form_complete event data
		tealiumParams: ->
			_params = new URLSearchParams()
			_params.append 'tealium_account', "owletbabycare"
			_params.append 'tealium_datasource', 'jhjdej' # https://my.tealiumiq.com/datacloud/en-US/?account=owletbabycare&profile=main#data-sources/jhjdej
			_params.append 'tealium_profile', "main"
			_params.append 'tealium_event', "Form_complete"
			_params.append 'leanplum_app_id', process.env.LEANPLUM_APP_ID
			_params.append 'leanplum_client_key', process.env.LEANPLUM_CLIENT_KEY
			_params.append 'customer_email', @form.email
			_params.append 'formID', "Footer - #{@countryCode?.toUpperCase()}"
			_params.append 'tealium_visitor_id', utag?.data?.tealium_visitor_id
			_params.append 'due_date', @form.date
			_params.append 'language_code', @languageCode
			_params.append 'country_code', @countryCode?.toUpperCase()
			_params.append 'buyer_accepts_marketing', 'true'
			_params.append 'completion_date', ( new Date() ).toISOString()?.split('T')?[0]
			return _params

		checkValidEmail: ->
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(@form.email)

	watch:
		# On success, pass form submition data to tealium, which will be passed to
		# Leanplum via tealium event connectors
		# Event Specs: https://my.tealiumiq.com/datacloud/en-US/?account=owletbabycare&profile=main#event-specifications/76dab507-fe09-48f7-ad1e-75a7387c49c0
		# Event Connector: https://my.tealiumiq.com/datacloud/en-US/?account=owletbabycare&profile=main#event-stream/actions/5004_1
		success: (success) ->
			return unless success
			@$tealiumEcomm.emailSignup @form.email
			formTealiumResponse = await @$axios.get @tealiumEndpoint

	mounted: ->
		@isMobile =	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

	methods:
		onSubmit: (e) ->
			e.preventDefault()
			try
				@success = false
				@error = false
				@loading = true

				@checkForm()

				if @errors.length
					throw new Error()

				response = await @$axios.post @jotFormEndpoint, @params

				@loading = false
				@success = true

			catch error
				@error = true
				console.log error
				@loading=false

		checkForm: ->
			@errors = []

			if !@form.email.length then @errors.push "Please enter email."

			else if !@checkValidEmail then @errors.push "Please enter valid email."

			if !@form.terms then @errors.push "Please agree to terms and conditions."

			return @errors



